var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-container",
        [
          _c("CommonTree", {
            attrs: {
              treeTitle: "组织架构",
              treeData: _vm.treeData,
              defaultProps: _vm.defaultProps,
              searchTitle: _vm.searchTitle,
              isShowdig: false,
              showCheckbox: false,
              treeExpand: false,
              expandOnClickNode: false,
              defaultCheckedKeys: _vm.defaultCheckedKeys,
              nodeKey: "id",
            },
            on: { getNodeClick: _vm.treeNodeClick },
          }),
          _c(
            "div",
            { staticStyle: { width: "calc(100% - 275px)" } },
            [
              _c("head-layout", {
                attrs: {
                  "head-title": "下发任务详情",
                  "head-btn-options": _vm.headBtnOptions,
                },
                on: { "head-cancel": _vm.headCancel },
              }),
              _c("grid-head-layout", {
                ref: "gridHeadLayout",
                attrs: { "search-columns": _vm.searchColumns },
                on: {
                  "grid-head-search": _vm.gridHeadSearch,
                  "grid-head-empty": _vm.gridHeadEmpty,
                },
              }),
              _vm.gridShow
                ? _c("grid-layout", {
                    ref: "gridLayOut",
                    attrs: {
                      "table-options": _vm.tableOption,
                      "table-data": _vm.tableData,
                      "table-loading": _vm.tableLoading,
                      "data-total": _vm.page.total,
                      page: _vm.page,
                    },
                    on: {
                      "page-current-change": _vm.onLoad,
                      "page-size-change": _vm.onLoad,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }